<template>
  <div id="app">
    <van-nav-bar
  left-text="返回"
   @click-left="onClickLeft"
  left-arrow
/>
        <van-row>
  <van-col span="10">
<van-image  :src="require('@/assets/logo/logo.jpg')"/>
  </van-col>
</van-row>
  <van-row>
<van-image  :src="require('@/assets/logo/banner.png')"/>
</van-row>
    <router-view/>
  </div>
</template>

<script>
import { Col, Row } from 'vant'
import { Image as VanImage,NavBar} from 'vant'
export default {
    components:{
        [Col.name]:Col,
        [Row.name]:Row,
        [NavBar.name]:NavBar,
        [VanImage.name]:VanImage,
    },
  name: 'App',
  methods:
  {
     onClickLeft() {
      window.location.href = "https://chanba.sxcia.cn:8001/";
    },
  }
}
</script>

<style>
#app {
  font-family: SimHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  
}
.title{
     font-weight: bold;
     font-size: 1rem;
 }
 .wel{
     margin:.5rem 1rem;
     
 }
 *{
   margin: 0;
  padding: 0;
 }
</style>
