<template>
   <div>
       <div class="title">查询购票信息</div>
<van-form @submit="onSubmit">
  <van-field style="margin:1rem"
    v-model="mobile"
    name="mobile"
    label="购票手机号"
    placeholder="请输入手机号"
    :rules="[{ required: true, message: '请输入手机号' }]"
  />
  
  <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit">
      查询
    </van-button>
    <div v-for="info in list" :key="info.id" style="margin:1rem;font-size:.8rem">
  <van-row style="margin-top:1rem">
  <van-col span="5">姓名:</van-col>
  <van-col span="17">{{ info.name}}</van-col>
</van-row>
 <van-row style="margin-top:.5rem">
  <van-col span="5">预约日期</van-col>
  <van-col span="17">{{ info.order_date}} | {{info.order_type}}</van-col>
</van-row>
<van-divider />
    </div>
  </div>
</van-form>
   </div>
</template>
<script>
import { Button,Form,Field,Col, Row,Divider } from 'vant';
export default {
    components:{
         [Button.name]:Button,
         [Form.name]:Form,
         [Field.name]:Field,
         [Col.name]:Col,
         [Row.name]:Row,
         [Divider.name]:Divider
    },
    data(){
      return{
        list:null,
        mobile:""
      }
    },
    methods:{
      onSubmit(values)
      {
        this.$http
           .get('https://chanba.sxcia.cn/api/index/orderList?mobile='+values.mobile)
           .then(response=>{
             if(response.data.data != "")
             {
               this.list = response.data.data
             }
           })
        
      }
    }
}

</script>