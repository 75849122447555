import VueRouter from 'vue-router'
import Vue from 'vue'
// import Welcome from '../view/welcome'
import Ticket from '../view/ticket'
import Info from '../view/info'
import Select from '../view/select'

Vue.use(VueRouter)
const routes = [
    { path: '/', component: Ticket},
    { path: '/ticket', component: Ticket},
    { path: '/info', component: Info},
    { path: '/select', component: Select}
  ]
  const router = new VueRouter({
    routes, // (缩写) 相当于 routes: routes
  })
  export default router
