<template>
<div style="margin:.3rem">
<div class="title">
    填写预约信息
</div>
<div class="content">
<van-form @submit="onSubmit">
<van-cell-group >
<van-cell title="入园时间" is-link to="/ticket"  value="9:00-13:00" />
  <van-field  name="mobile"  v-model="phone" type="tel" label="手机号码" placeholder="请输入手机号码" required :rules="[{ pattern, message: '请输入正确的手机号码' }]"/>
<van-row style="font-size:1rem" v-show="listShow">
  <van-col span="16" style="margin:.5rem;font-size:1rem">
    <van-row style="margin:.8rem"><span class="formlist">姓名:{{ formDate.username }}</span>
    </van-row>
    <van-row style="margin:.8rem">
      <span class="formlist">证件号码: {{ formDate.id }}</span>
    </van-row>
  </van-col>
  <van-col span="5" style="margin-top:1rem">
      <van-icon name="delete"  size="1.5rem" @click="deleteList"/>
  </van-col>
</van-row>
</van-cell-group>
<van-button v-show="addShow" native-type="submit" icon="plus" type="primary" style="margin:1rem" round>添加参观者(最多一人)</van-button>
<van-button v-show="successShow" native-type="submit" type="primary" style="margin:1rem" round>确定预约</van-button>
</van-form>
 <div class="addPopup">
     <van-popup v-model="show" position="bottom" :style="{ height: '100%' }"   closeable close-icon="close" >
         <div style="margin:1rem">
         <van-form @submit="onSubmitPopup">
             <div class="title">
                填写预约信息
           </div>
           <van-field v-model="username"
    name="username"
    label="姓名"
    required
    placeholder="请输入姓名"
    :rules="[{ required: true, message: '请填写姓名' }]"
  />
  <van-field name="idType" label="证件类型" required>
  <template #input>
    <van-radio-group v-model="idType"  direction="horizontal">
      <van-radio name="1">身份证</van-radio>
      <van-radio name="2">护照</van-radio>
    </van-radio-group>
  </template>
</van-field>
     <van-field v-model="idNumber"
    name="id"
    label="证件号码"
    required
    placeholder="请输入证件号码"
    :rules="[{ required: true, message: '请填写证件号码' }]"
  />
  <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
  1.4米以下的儿童或6岁以下的儿童不需要预约
</van-notice-bar>
<div class="title">
                健康状况
</div>
<div class="question">
    *以上观众当前是否在西安？【含西安所有区县(市)】<van-tag type="primary">单选</van-tag>
</div>
<van-field name="q1" label=""  :rules="[{required: true, message: '未选择' }]">
  <template #input>
    <van-radio-group v-model="q1" direction="horizontal">
      <van-radio name="1">是</van-radio>
      <van-radio name="2" style="margin-left:6rem">否</van-radio>
    </van-radio-group>
  </template>
</van-field>
<div class="question">
    *以上观众最近14天是否离开过西安？<van-tag type="primary">单选</van-tag>
</div>
<van-field name="q2" label="" :rules="[{ required: true, message: '未选择' }]">
  <template #input>
    <van-radio-group v-model="q2" direction="horizontal">
      <van-radio name="1">是</van-radio>
      <van-radio name="2" style="margin-left:6rem">否</van-radio>
    </van-radio-group>
  </template>
</van-field>
<div class="question">
    *近14天是否接触过新冠确诊病人或疑似病人？<van-tag type="primary">单选</van-tag>
</div>
<van-field name="q3" label="" :rules="[{ required:true, message: '未选择' }]">
  <template #input>
    <van-radio-group v-model="q3" direction="horizontal">
      <van-radio name="1" >是</van-radio>
      <van-radio name="2" style="margin-left:6rem">否</van-radio>
    </van-radio-group>
  </template>
</van-field>
<div class="question">
    *当前健康状况（若有以下状况，请在方框内勾选<van-tag type="primary">多选</van-tag>
</div>
<van-field name="q4" label="" :rules="[{ required:true, message: '未选择' }]">
  <template #input>
  
 <van-checkbox-group v-model="q4" direction="horizontal">
         <van-row>
  <van-col span="12">
 <van-checkbox name="1" shape="square" >无异常</van-checkbox>
  </van-col>
  <van-col span="12">
  <van-checkbox name="2" shape="square">发热(≥37.3°C)</van-checkbox>
  </van-col>
  <van-col span="12">
      <van-checkbox name="3" shape="square">乏力</van-checkbox>
  </van-col>
  <van-col span="12">
     <van-checkbox name="4" shape="square">干咳</van-checkbox>
  </van-col>
    <van-col span="12">
     <van-checkbox name="5" shape="square">鼻塞</van-checkbox>
  </van-col>
    <van-col span="12">
     <van-checkbox name="6" shape="square">流涕</van-checkbox>
  </van-col>
  <van-col span="12">
     <van-checkbox name="7" shape="square">咽痛</van-checkbox>
  </van-col>
  <van-col span="12">
     <van-checkbox name="8" shape="square">腹泻</van-checkbox>
  </van-col>
  </van-row>
    </van-checkbox-group>
  </template>
</van-field>
<div class="question">
    *来馆方式<van-tag type="primary">单选</van-tag>
</div>
<van-field name="q5" label="" :rules="[{ required:true, message: '未选择' }]">
  <template #input>
    <van-radio-group v-model="q5" direction="horizontal">
      <van-row>
          <van-col span="12">
      <van-radio name="1" >自驾</van-radio>
          </van-col>
            <van-col span="12">
      <van-radio name="2" >公交地铁</van-radio>
          </van-col>
            <van-col span="12">
      <van-radio name="3" >打车</van-radio>
            </van-col>
      <van-col span="12">
      <van-radio name="4" >其他</van-radio>
          </van-col>
      </van-row>
    </van-radio-group>
  </template>
</van-field>
<van-button native-type="submit"  type="info" round block >确定</van-button>
 </van-form>
</div>
     </van-popup>
 </div>
</div>
</div>
</template>
<script>
import {Icon,Col,Row,Checkbox,CheckboxGroup,Tag, Dialog,Cell,CellGroup,Field,Button,Form,Popup,Radio,RadioGroup,NoticeBar } from 'vant'
export default {
    components:{
        [Dialog.name]:Dialog,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Field.name]:Field,
        [Button.name]:Button,
        [Form.name]:Form,
        [Popup.name]:Popup,
        [Radio.name]:Radio,
        [RadioGroup.name]:RadioGroup,
        [NoticeBar.name]:NoticeBar,
        [Tag.name]:Tag,
        [Checkbox.name]:Checkbox,
        [CheckboxGroup.name]:CheckboxGroup,
        [Col.name]:Col,
        [Row.name]:Row,
        [Icon.name]:Icon,
    },
    data(){
        return{
            phone:"",
            username:"",
            idNumber:"",
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            show:false,
            idType:"",
            q1:"",
            q2:"",
            q3:"",
            q4:[],
            q5:"",
            listShow:false,
            formDate:{},
            addShow:true,
            successShow:false
        }
    },
    methods:{
    onSubmit(values) {
      console.log('submit', values);
      this.show=true
    },
    onSubmitPopup(values){
        this.formDate = values
        this.show=false
        this.listShow= true
        this.addShow= false
        this.successShow= true
    },
    deleteList(){
      this.formDate= {},
      this.listShow= false,
      this.addShow = true,
      this.successShow = false
    }
    },
    
 mounted(){
    Dialog.alert({
  title: '防控信息采集声明',
  message: '  根据疫情防控工作需要，请您在预约过程中如实填写个人健康信息。该信息仅用于疫情防控工作，采集的信息由浐灞国家湿地公园单独保存，除非得到您的允许，绝不会用于广告、转发给其他地方及作其他使用。',
  theme: 'round-button',
  confirmButtonText:"我已阅读并同意",
  confirmButtonColor:"#07c160"
}).then(() => {
  // on close
});
}
}
</script>
<style scoped>
.content{
    font-size: 2rem;
}
.question{
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: .5rem;
}
.van-checkbox,.van-radio{
    margin: .5rem;
}
.formlist{
  font-size: .9rem;
}
</style>