<template>
<div class="wel" v-show="siteStatus">
<van-row class="title">
  <van-col span="16">浐灞国家湿地公园线上预约</van-col>
  <van-col span="8" style="color:#07c160;text-align:right" @click="select">预约查询</van-col>
</van-row>
<h5 style="margin:.5rem;color:#666666">
    为了保障景区安全及您的游览体验，请在下方完成入园预约并持预约码领取门票
</h5>
<h5 style="margin:.5rem;color:red">
    
</h5>
<h4>*预约日期</h4>
<van-row style="margin:.5rem">
<van-button :plain="date1" hairline @click=date1Click  type="primary" style="width:33%;height:2.5rem">{{one}}</van-button>
<van-button :plain="date2" hairline @click=date2Click  type="primary" style="width:33%;height:2.5rem">{{two}}</van-button>
<van-button :plain="date3" hairline @click=date3Click  type="primary" style="width:33%;height:2.5rem">{{three}}</van-button>
</van-row>
<h4>*预约时间段</h4>
<van-row style="margin:.5rem" >
 <van-button :disabled="time1Button" :plain="time1" hairline @click=time1Click   type="primary" style="width:49%;height:2.5rem">{{time1Text}}<br>{{time1Status}}</van-button>
 <van-button :disabled="time2Button"  :plain="time2" hairline @click=time2Click   type="primary" style="width:50%;height:2.5rem">{{time2Text}}<br>{{time2Status}}</van-button>
</van-row>
<h4>*请完善预约信息</h4>
<van-form @submit="onSubmit">
 <van-field
    v-model="username"
    name="username"
    label="姓名"
    placeholder="请输入姓名"
    :rules="[{ required: true, message: '请填写姓名' }]"
  />
   <van-field
    v-model="id"
    name="id"
    label="身份证"
    placeholder="请输入身份证号"
    :rules="[{ validator, message: '请填写正确得身份证号' }]"
  />
  <van-field
    v-model="mobile"
    name="mobile"
    label="手机号"
    placeholder="请输入手机号"
    :rules="[{ pattern, message: '请输入正确得手机号' }]"
  />
    <van-field
  v-model="sms"
  name="sms"
  center
  clearable
  label="短信验证码"
  placeholder="请输入短信验证码"
  :rules="[{ required:true, message: '验证码不能为空' }]"
>
  <template #button>
    <van-button :disabled="btnSms" size="small" type="primary" @click="sendSms">{{btnSmsContent}}</van-button>
  </template>
</van-field>

  <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit">
      提交
    </van-button>
  </div>
</van-form>
</div>
</template>
<script>
import { Col, Row,NoticeBar, RadioGroup, Radio,Form,Field,Button,Toast,Dialog } from 'vant';
export default {
    components:{
        [Col.name]:Col,
        [Row.name]:Row,
        [NoticeBar.name]:NoticeBar,
        [RadioGroup.name]:RadioGroup,
        [Radio.name]:Radio,
        [Form.name]:Form,
        [Field.name]:Field,
        [Button.name]:Button,
        [Toast.name]:Toast,
        [Dialog.name]:Dialog

    },
    data(){
      return{
        mobile:"",
        sms:"",
        username:"",
        id:"",
        pattern:/^1[3456789]\d{9}$/,
        patternId:/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
        radio:"1",
        date1:false,
        date2:true,
        date3:true,
        time1:false,
        time2:true,
        one:"",
        oneY:"",
        two:"",
        twoY:"",
        three:"",
        threeY:"",
        time1Text:"",
        time2Text:"",
        time1Status:"充足",
        time2Status:"充足",
        time1Button:false,
        time2Button:false,
        time:120,
        btnSmsContent:"发送验证码",
        btnSms:false,
        date:"",
        dateType:"",
        siteStatus:true,
        statusMessage:""
      }
    },
    created(){
        this.checkStatus()
        const today = new Date();
        const tomorrow = new Date(today);
        const afterTomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate()+1);
        afterTomorrow.setDate(afterTomorrow.getDate()+2);
        console.log(afterTomorrow);
        const one = today.getMonth()+1+"月"+today.getDate()+"日";
        const two = tomorrow.getMonth()+1+"月"+tomorrow.getDate()+"日";
        const three = afterTomorrow.getMonth()+1+"月"+afterTomorrow.getDate()+"日";
        console.log(three)
        
        this.one = one;
        this.oneY = today.getFullYear()
        this.two = two;
        this.twoY = tomorrow.getFullYear()
        this.three = three;
        this.threeY  = afterTomorrow.getFullYear()
    },
    mounted()
    {
    
      this.$http
        .get('https://chanba.sxcia.cn/api/index/dateType')
        .then(response => (
            this.time1Text= response.data.data[0].name,
            this.time2Text= response.data.data[1].name,
            this.date = this.oneY+"年"+this.one
          )).then(res=>{

            console.log(res)            
            if(this.time1Button)
            {
              this.dateType = this.time2Text
            }
            if(this.time2Button)
            {
              this.dateType = "";
            }
            
          }

          );
        var now = new Date().getHours();
        this.date = this.oneY+ "年"+ this.one;
        this.dateType = this.time1Text;
      
        if(now>=12)
        {
          this.time1Status = "售完"
          this.time1Button = true
        }
         if(now>=17)
        {
          this.time2Status = "售完"
          this.time2Button = true
        }
        if(this.time1Button)
        {
          this.time1 =true
          this.time2 =false
        }
        if(this.time2Button)
        {
          this.time2 =true
        }

    },
    methods:{
       checkStatus()
      {
           this.$http
        .get('https://chanba.sxcia.cn/api/index/getReserveStatus')
        .then(response => (
            this.siteStatus = response.data.data == null?true:false,
            this.statusMessage = response.data.data
          )).then(()=>
          {
            if(!this.status)
          {
             Dialog.alert({
                  title: this.statusMessage.title,
                  message: this.statusMessage.content,
                }).then(() => {
                  // on close
                });
          }
          }
          )
      },
      onSubmit(values){
        console.log(values);
        console.log(this.date);
        console.log(this.dateType);
           this.$http
           .get('https://chanba.sxcia.cn/api/index/createOrder?id='+values.id+"&mobile="+values.mobile+"&sms="+values.sms+"&username="+values.username+"&date="+this.date+"&datetype="+this.dateType)
           .then(response=>{
             console.log(response.data);
             if(!response.data.code)
             {
               Toast(response.data.msg);
               return;
             }else{
               Dialog({ message: '预约已成功，您得预约码已发送至手机' });
               this.username = this.id = this.sms = "";
             }

           })
        
      },
      validator(val) {
      return/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
    },
      select(){
        this.$router.push({path:'/select'})
      },
      date1Click()
      {
        this.date=this.oneY+"年"+this.one;
        this.date1= false;
        this.date2= this.date3= true;
         var now = new Date().getHours();
           if(now>=12)
        {
          this.time1Status = "售完"
          this.time1Button = true
        }
         if(now>=17)
        {
          this.time2Status = "售完"
          this.time2Button = true
        }
      },
      date2Click()
      {
            this.date=this.twoY+"年"+this.two;
        this.date2= false;
        this.date1= this.date3= true;
         this.time1Status = "充足"
        this.time2Status = "充足"
        this.time1Button =this.time2Button = false

      },
       date3Click()
      {
       this.date=this.threeY+"年"+this.three;
       this.date3= false;
        this.date2= this.date1= true;
         this.time1Status = "充足"
        this.time2Status = "充足"
        this.time1Button =this.time2Button = false
      },
      time1Click()
      {
        this.dateType = this.time1Text;
        this.time1 =false;
        this.time2= true
      },
      time2Click()
      {
        this.dateType = this.time2Text;
        this.time1 =true;
        this.time2= false
      },
      sendSms()
      {
          if(!this.mobile.replace(/^1[3456789]\d{9}$/))
          {
            Toast('请输入正确得手机号码');
            return;
          }
          this.$http
        .get('https://chanba.sxcia.cn/api/index/sendSms'+"?mobile="+this.mobile)
        .then(response => (
            console.log(response)
          ));
          this.btnSms = true
          this.getTime();

      },
         getTime(){
           this.btnSmsContent=this.time+"秒"
         this.timer= setInterval(() => {
              this.time--
              this.btnSmsContent=this.time+"秒"
              if(this.time === 0){
                  this.btnSmsContent="发送验证码"
               clearInterval(this.timer)
                  this.btnSms =false
              }
          }, 1000);
      },
    }
}
</script>
<style scoped>
 .list{
     margin-top: .5rem;
 }
</style>